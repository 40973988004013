import { createWebHistory, createRouter } from "vue-router";
import routes from './routes';
import appConfig from "../../app.config";

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

// Guard de navegação global
router.beforeEach((routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some(route => route.meta.authRequired);
  const token = sessionStorage.getItem('authToken');
  const lockedScreen = sessionStorage.getItem('lockedScreen') === 'true'; // Verifica se a tela está bloqueada

  console.log(`Navigating from ${routeFrom.path} to ${routeTo.path}`);
  console.log(`Token exists: ${!!token}`);
  console.log(`Auth required: ${authRequired}`);
  console.log(`Locked Screen: ${lockedScreen}`);

  // Bloqueia a navegação se a tela está bloqueada
  if (lockedScreen) {
    if (routeTo.name !== 'lock-screen-cover') {
      return next({ name: 'lock-screen-cover', replace: true });
    }
    return next();
  }

  // Se a rota requer autenticação e não há token, redireciona para login
  if (authRequired && !token) {
    if (routeTo.name !== 'login') {
      return next({ name: 'login' });
    }
    return next();
  }

  // Se o usuário já está logado e tenta acessar a página de login, redireciona para a página inicial
  if (routeTo.name === 'login' && token) {
    return next({ path: '/pages/starter' });
  }

  // Permitir acesso à página de logout, independentemente do token
  if (routeTo.name === 'logout') {
    return next(); // Permitir navegação para o componente de logout
  }

  next(); // Permitir a navegação para outras rotas
});

router.beforeResolve((routeTo, routeFrom, next) => {
  document.title = routeTo.meta.title + ' | ' + appConfig.title;
  next();
});

export default router;
