import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL; // URL do seu backend

export const authService = {
  login(email, password) {
    // Usar o email como device_name para a primeira tentativa de login
    return axios.post(`${API_URL}/auth`, { email, password, device_name: email })
      .then(response => {
        const { token, name } = response.data;

        // Armazenar o token e o nome do usuário
        sessionStorage.setItem('authToken', token);
        sessionStorage.setItem('userName', name); // Armazena o nome do usuário para usos futuros

        return response.data;
      });
  },

  logout() {
    const token = sessionStorage.getItem('authToken');
    return axios.post(`${API_URL}/logout`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(response => response.data);
  },

  validate() {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      return Promise.reject('No token found');
    }
    return axios.get(`${API_URL}/validate`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(response => response.data);
  },

  getUserDetails(email) {
    return axios.get(`${API_URL}/users?email=${email}`)
      .then(response => response.data);
  }
};
