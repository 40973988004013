// store/modules/auth.js

import { authService } from '../../services/authService';

const state = {
  currentUser: JSON.parse(sessionStorage.getItem('authUser')),
  email: sessionStorage.getItem('userEmail') || '',
  userName: sessionStorage.getItem('userName') || '', // Armazenar o nome do usuário
  isLocked: false // Propriedade para verificar se a tela está bloqueada
};

const mutations = {
  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
    saveState('auth.currentUser', user);
    if (user && user.email) {
      state.email = user.email;
      sessionStorage.setItem('userEmail', user.email);
    }
    if (user && user.name) {
      state.userName = user.name; // Armazenar o nome do usuário
      sessionStorage.setItem('userName', user.name); // Armazenar o nome no sessionStorage
    }
  },
  SET_LOCKED_STATE(state, locked) {
    state.isLocked = locked;
  },
  CLEAR_AUTH(state) {
    state.currentUser = null;
    state.email = '';
    state.userName = ''; // Limpar o nome do usuário
    sessionStorage.removeItem('authUser');
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('userEmail');
    sessionStorage.removeItem('userName'); // Remover o nome do sessionStorage
  }
};

const getters = {
  loggedIn(state) {
    return !!state.currentUser;
  },
  isLocked(state) {
    return state.isLocked;
  },
  userEmail(state) {
    return state.email;
  },
  userName(state) {
    return state.userName; // Getter para o nome do usuário
  }
};

const actions = {
  async logIn({ commit }, { email, password }) {
    try {
      const response = await authService.login(email, password);
      commit('SET_CURRENT_USER', { ...response, email }); // Armazena o email e os dados do usuário
      sessionStorage.setItem('authToken', response.token);
      return response;
    } catch (error) {
      console.error('Erro de login:', error);
      throw error;
    }
  },

  logOut({ commit }) {
    commit('CLEAR_AUTH');
    return authService.logout();
  },

  lockScreen({ commit }) {
    commit('SET_LOCKED_STATE', true);
  },

  unlockScreen({ commit }) {
    commit('SET_LOCKED_STATE', false);
  },

  async validate({ commit, state }) {
    if (!state.currentUser) return null;

    try {
      const user = await authService.validate();
      commit('SET_CURRENT_USER', user);
      return user;
    } catch {
      commit('CLEAR_AUTH');
      return null;
    }
  },
};

function saveState(key, state) {
  window.sessionStorage.setItem(key, JSON.stringify(state));
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
