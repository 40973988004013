import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";

import BootstrapVueNext from 'bootstrap-vue-next';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import { vMaska } from "maska";
import VueFeather from 'vue-feather';

import '@/assets/scss/config/modern/app.scss';
import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

const app = createApp(App);

window.addEventListener('popstate', function () {
    // When the user tries to navigate back, push them back to the current path
    history.pushState(null, null, window.location.pathname);
});


app.use(store)
   .use(router)
   .use(VueApexCharts)
   .use(BootstrapVueNext)
   .component(VueFeather.type, VueFeather)
   .directive("maska", vMaska)
   .use(i18n)
   .use(vClickOutside)
   .mount('#app');

