// store/index.js

import { createStore } from 'vuex';
import auth from './modules/auth';   // Importar o módulo auth
import layout from './modules/layout'; // Importar o módulo layout

const store = createStore({
  modules: {
    auth,   // Registrar o módulo de autenticação
    layout, // Registrar o módulo layout
  },
});

export default store;
